// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-connect-js": () => import("./../../../src/pages/connect.js" /* webpackChunkName: "component---src-pages-connect-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-programs-events-js": () => import("./../../../src/pages/programs-events.js" /* webpackChunkName: "component---src-pages-programs-events-js" */),
  "component---src-pages-swim-lessons-registration-espanol-js": () => import("./../../../src/pages/swim-lessons-registration-espanol.js" /* webpackChunkName: "component---src-pages-swim-lessons-registration-espanol-js" */),
  "component---src-pages-swim-lessons-registration-js": () => import("./../../../src/pages/swim-lessons-registration.js" /* webpackChunkName: "component---src-pages-swim-lessons-registration-js" */),
  "component---src-pages-the-project-js": () => import("./../../../src/pages/the-project.js" /* webpackChunkName: "component---src-pages-the-project-js" */),
  "component---src-templates-blog-template-jsx": () => import("./../../../src/templates/blog-template.jsx" /* webpackChunkName: "component---src-templates-blog-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/page-template.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-posts-page-template-jsx": () => import("./../../../src/templates/posts-page-template.jsx" /* webpackChunkName: "component---src-templates-posts-page-template-jsx" */),
  "component---src-templates-swim-lessons-page-template-jsx": () => import("./../../../src/templates/swim-lessons-page-template.jsx" /* webpackChunkName: "component---src-templates-swim-lessons-page-template-jsx" */)
}

